export function deduplicateById(arr) {
  return arr.reduce((acc, next) => { //get rid of same images
    if(!next?.id || acc.find(image => image.id === next.id)) {
      return acc
    }
    return [...acc, next ]
  }, [])
}

export function formatOptions(options) {
  return Object.entries(options)
    .map(([id, value]) => ({...value, id }))
    .filter(option => option.optionName)
}

export function stripHtmlTags(originalString) {
  if(!originalString) return ''
  
  return originalString.replace(/(<([^>]+)>)/gi, "");
}

export function makeCSV(content) {
  let csv = '';
  content.forEach(value => {
    value.forEach((item, i) => {
      let innerValue = item === null ? '' : item.toString();
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = '"' + result + '"'
      }
      if (i > 0) {csv += ','}
      csv += result;
    })
    csv += '\n';
  })
  return csv
}

export function toSnakeCase(str) {
  return str.replace(/[A-Z]/g, (letter, index) => {
    return index === 0 ? letter.toLowerCase() : '_' + letter.toLowerCase();
  }).replace(/\s+/g, '_').replace(/-/g, '_');
}