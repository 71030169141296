import React, { useContext, Fragment, useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import SEO from "../components/seo"
import TitleHeader from '@components/TitleHeader'
import { Button } from 'gatsby-theme-material-ui'
import { IconButton, useTheme, Grid, Container } from '@mui/material';
import { AddCircle, AddCircleOutline, RemoveCircle, RemoveCircleOutline } from '@mui/icons-material'
import { MyPriceListSetContext, MyPriceListStateContext } from '../context/myPriceListContext'
import currency from 'currency.js'
import { makeCSV } from '@src/utility'

const PREFIX = 'MyPriceListPage';

const classes = {
  container: `${PREFIX}-container`,
  actionsCell: `${PREFIX}-actionsCell`,
  totalCell: `${PREFIX}-totalCell`,
  table: `${PREFIX}-table`,
  attributeName: `${PREFIX}-attributeName`,
  actionsWrapper: `${PREFIX}-actionsWrapper`,
  buttonGrid: `${PREFIX}-buttonGrid`,
  optionRow: `${PREFIX}-optionRow`
};

const Root = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4)
    },
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
  },

  [`& .${classes.actionsCell}`]: {
  },

  [`& .${classes.totalCell}`]: {
    minWidth: 90,
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '146.2%',
    textAlign: 'center !important'
  },

  [`& .${classes.table}`]: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: theme.spacing(2),
    '& tbody': {
      '& tr:nth-child(2n)': {
        background: 'rgb(217, 234, 211)',
      },
      '& tr:nth-child(2n + 1)': {
        background: 'rgb(255, 542, 204)',
      },
    },
    '& td, & th': {
      textAlign: 'center',
      border: '1px solid rgb(220, 219, 216)',
    },
    '& thead th': {
      background: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontFamily: 'Krub',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px', 
      lineHeight: '146.4%',
      textTransform: 'capitalize'
    },
    '& tfoot th': {
      textAlign: 'left',
      padding: theme.spacing(0, 0.5)
    }
  },

  [`& .${classes.attributeName}`]: {
    fontFamily: 'Krub',
    fontStyle: 'normal'
  },

  [`& .${classes.actionsWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  [`& .${classes.buttonGrid}`]: {
    textAlign: 'left',
    [theme.breakpoints.up("sm")]: {
      textAlign: 'right'
    }
  },

  [`& .${classes.optionRow}`]: {
    '&:nth-child(2n)': {
      background: 'rgba(217, 234, 211, 0.2) !important',
    },
    '&:nth-child(2n + 1)': {
      background: 'rgba(255, 542, 204, 0.2) !important',
    },
  }
}));

const DownloadButton = ({ myPriceList, total }) => {
  const [ fileDownloadUrl, setFileDownloadUrl ] = useState(null)
  const doFileDownload = useRef(null)

  const download = (event) => {
    event.preventDefault();
    let contents = [];
    contents.push (["NAME OF EQUIPMENT", "PRICE", "UNITS"]);
    contents.push([''])

    myPriceList.forEach(row => {
      contents.push([row.name.toUpperCase(), row.price, row.quantity])
      if(row.options?.length) {
        const options = row.options.filter(option => option.units > 0)
        if(options.length) {
          contents.push (["Options"]);
          options.forEach(option => {
            contents.push([option.optionName, currency(option.optionPrice).format(), option.units])
          })
        }
      }
      contents.push([''])
    });

    contents.push([''])
    contents.push(['ESTIMATED COST', currency(total).format()])

    const output = makeCSV(contents);
    // Download it
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    setFileDownloadUrl(fileDownloadUrl);    
  }

  useEffect(() => {
    if(!fileDownloadUrl || !doFileDownload.current) return

    doFileDownload.current.click(); 
    URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
    setFileDownloadUrl("")
  }, [fileDownloadUrl])

  return (
    (<>
      <Button
        onClick={download}
        variant="outlined"
        color="primary"
        fullWidth
        disabled={typeof window == 'undefined' || myPriceList.length < 1}
      >
        download
      </Button>
      <a 
        style={{
          visibility: 'hidden',
        }}
        download={`my-price-list-farm-equipment-plus.csv`}
        href={fileDownloadUrl}
        ref={doFileDownload}
      >
        download it
      </a>
    </>)
  );
}

function MyPriceListPage() {
  const myPriceList = useContext(MyPriceListStateContext)
  const setMyPriceList = useContext(MyPriceListSetContext)

  const theme = useTheme()
  
  const total = myPriceList.reduce((acc, product) => {
    const optionsTotal = product.options?.length ?
      product.options.reduce((acc, option) => {
        return acc + option.units * option.optionPrice
      }, 0)
      : 0;
    return acc + optionsTotal + product.quantity * (parseFloat(currency(product.price).add(0), 10) || 0)
  }, 0)

  function increaseQuantity(id, category) {
    const foundProduct = myPriceList.find(product => product.id === id)
    if(category === "Used Equipment" && foundProduct.quantity > 0) return;

    setMyPriceList([
      ...myPriceList.filter(product => product.id !== id),
      { ...foundProduct, quantity: foundProduct.quantity + 1}
    ])
  }

  function reduceQuantity(id) {
    const foundProduct = myPriceList.find(product => product.id === id)
    if(foundProduct.quantity < 1) return;

    setMyPriceList([
      ...myPriceList.filter(product => product.id !== id),
      { ...foundProduct, quantity: foundProduct.quantity - 1}
    ])
  }

  function increaseOptionQuantity(productId, optionId) {
    const foundProduct = myPriceList.find(product => product.id === productId)
    const foundOption = foundProduct.options.find(option => option.id === optionId)
    if(foundOption.units > 19) return;

    setMyPriceList([
      ...myPriceList.filter(product => product.id !== productId),
      { 
        ...foundProduct, 
        options: [
          ...foundProduct.options.filter(option => option.id !== optionId),
          { ...foundOption, units: foundOption.units + 1 }
        ]
      }
    ])
  }

  function reduceOptionQuantity(productId, optionId) {
    const foundProduct = myPriceList.find(product => product.id === productId)
    const foundOption = foundProduct.options.find(option => option.id === optionId)
    if(foundOption.units < 1) return;

    setMyPriceList([
      ...myPriceList.filter(product => product.id !== productId),
      { 
        ...foundProduct, 
        options: [
          ...foundProduct.options.filter(option => option.id !== optionId),
          { ...foundOption, units: foundOption.units - 1 }
        ]
      }
    ])
  }

  function clearPriceList() {
    setMyPriceList([])
  }
  
  return (
    <>
      <SEO 
        title="Your price list of the Farm Equipment Plus items" 
        description="Farm Equipment Plus specializes in grain handling and grain drying equipment. If you need help with a project or any particular equipment, please contact us. We may have inventory not yet listed on our website." />
      <Root maxWidth="md" disableGutters>
        <div className={classes.container}>
          <TitleHeader
            title='My price list'
          />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={8} md={6}>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>name of equipment</th>
                    <th>price</th>
                    <th className={classes.actionsHeader}>units</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    myPriceList
                    .sort((a, b) => {
                      if(a.name < b.name) { return -1; }
                      if(a.name > b.name) { return 1; }
                      return 0;
                    })
                    .map(product => {
                      return (
                        <Fragment key={product.key}>
                          <tr>
                            <td>{product.name || 'Unknown product'}</td>
                            <td>{product.price || '-'}</td>
                            <td className={classes.actionsCell}>
                              <div className={classes.actionsWrapper}>
                                <IconButton
                                  aria-label="decrease by 1"
                                  onClick={() => reduceQuantity(product.id)}
                                  color="primary"
                                  size="medium"
                                  disabled={product.quantity < 1}>
                                  <RemoveCircle />
                                </IconButton>
                                <span>{product.quantity}</span>
                                <IconButton
                                  aria-label="increase by 1"
                                  onClick={() => increaseQuantity(product.id, product.category)}
                                  color="primary"
                                  size="medium"
                                  disabled={product.category.name === "Used Equipment" && product.quantity > 0}>
                                  <AddCircle />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                          {
                            product.options?.length ?
                            <>
                              <tr>
                                <th colSpan={3} className={classes.attributeName}>Options</th>
                              </tr>
                                    {
                                      product.options
                                        .sort((a, b) => {
                                          if(a.optionName < b.optionName) { return -1; }
                                          if(a.optionName > b.optionName) { return 1; }
                                          return 0;
                                        })
                                        .map(option => {
                                        return (
                                          <tr key={option.optionName} className={classes.optionRow}>
                                            <td>{option.optionName}</td>
                                            <td>{`$${option.optionPrice}`}</td>
                                            <td className={classes.actionsCell}>
                                              <div className={classes.actionsWrapper}>
                                                <IconButton
                                                  aria-label="decrease by 1"
                                                  onClick={() => reduceOptionQuantity(product.id, option.id)}
                                                  color="primary"
                                                  size="small"
                                                  disabled={option.units < 1}>
                                                  <RemoveCircleOutline/>
                                                </IconButton>
                                                <span>{option.units}</span>
                                                <IconButton
                                                  aria-label="increase by 1"
                                                  onClick={() => increaseOptionQuantity(product.id, option.id)}
                                                  color="primary"
                                                  size="small"
                                                  disabled={option.units > 19}
                                                  >
                                                  <AddCircleOutline />
                                                </IconButton>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                            </>
                            : 
                            null
                          }
                        </Fragment>
                      )
                    })
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <th>Estimated cost</th>
                    <th className={classes.totalCell}>{total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</th>
                  </tr>
                </tfoot>
              </table>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button onClick={clearPriceList} variant="outlined" color="secondary" fullWidth>remove all</Button>
                </Grid>
                <Grid item xs={6}>
                  <DownloadButton myPriceList={myPriceList} total={total} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={6} className={classes.buttonGrid}>
              <Button to="/all-price-lists" variant="contained" color="secondary">view all price lists</Button>
            </Grid>
          </Grid>
        </div>
      </Root>
    </>
  );
}

export default MyPriceListPage;